$background-color-all-pages: rgb(241, 243, 246);
$color-prompt: #0d0023;
$violet-primary: #1f002e;
$violet-primary-shade: #0d002397;
$violet-secondary: #3c096c;
$violet-light: #7b2cbf;
$violet-light-shade: #7a2cbf5c;
$violet-bg-shade: #1f002e4f;

$orange-primary: #ff6d00;
$orange-secondary: #ff7900;
$orange-light: #ff9e00;
$orange-light-shade: #ff9d00e4;

$green-primary: #00ff1e;
$green-primary-shade: #00ff1eb4;
$yellow-primary: #ffe100;
$red-primary: rgb(255, 0, 0);

$gray-primary: #374957;
$horizontal-line-color: #EFF1F4;
$input_background: #F1F3F6;
$seperator_background: rgba(220, 220, 220, 0.452);


$padding-top-all-pages: 3rem;
$top-components-height: 73px;

// Tables Sizes
$table-height-default: 520px;
$table-max-height-960: 480px;
$table-max-height-910: 430px;
$table-max-height-860: 380px;
$table-max-height-810: 330px;
$table-max-height-760: 280px;
$table-max-height-710: 230px;
$table-max-height-660: 180px;
$table-max-height-610: 130px;
$table-max-height-560: 80px;