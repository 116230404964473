@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';

// @keyframes appear_animation {
//     0% {
//         opacity: 0;
//     }
//     50% {
//         opacity: 1;
//     }
//     100%{
//         opacity: 0;
//     }
// }


.chat_div{
    width: 100%;
    height: 92%;

    min-height: 92%;
    max-height: 92%;

    @include flex-column-start-center;
    background-color: $violet-primary;

    // border: 1px dotted red;
}

.chat_div_main{
    width: 100%;
    height: 100%;

    @include flex-row-between-center;
    // overflow-y: hidden;
}

.chat_div_main_left{
    width: 40%;
    max-width: 40%;
    min-width: 40%;

    height: 100%;

    @include flex-row-center-center;
    box-shadow: inset 32rem 0 100px -100px $color-prompt;

    & img{
        max-width: 100%;
        height: 100%;
        padding: 0rem;
    }
}


.chat_div_main_right{
    width: 100%;
    height: 100%;
    @include flex-row-center-center;
    
    box-shadow: inset 20rem 0 60rem 80px $violet-primary;
    background: url('../../assets/images/chat.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    filter: brightness(100%);
    box-shadow: 100px;

}

.chat_div_chat{
    height: 100%;
    width: 100%;
    @include flex-column-end-center;

    margin-bottom: 1rem;
}

.chat_div_chat_scroll{
    width: 100%;
    height: calc(100% - 5rem);
    max-height: calc(100% - 5rem);

    @include flex-column-end-center;
    padding: 0rem 0.5rem;
    margin-bottom: 0.3rem;


// border: 1px solid red;
}

.chat_div_chat_scroll_100{
    width: 100%;
    height: auto;
    max-height: 100%;
    overflow-y: scroll;
    padding: 0rem 0.5rem;
}

.each_chat_div_parent_left{
    width: 100%;
    height: auto;
    @include flex-row-start-center;
    margin: 0.4rem 0rem;

}

.each_chat_div_parent_right{
    width: 100%;
    height: auto;
    @include flex-row-end-center;  
    margin: 0.4rem 0rem;

}

.each_chat_div_parent_center{
    width: 100%;
    height: 75vh;
    @include flex-row-center-center;  
    margin: 0.4rem 0rem;

    overflow-y: hidden !important;


}


.each_chat_div_left{
    max-width: 88%;
    min-height: 3rem;

    @include flex-row-start-start;
    gap: 1rem;

    padding: 0.5rem;
    margin: 0rem 0.5rem;
    border: 1px solid $violet-secondary;

    border-radius: 0rem 1rem 1rem 1rem;
    background-color:$violet-primary-shade;

}
.each_chat_div_left_profile{
    position: relative;
}

.each_chat_div_left_profile_mobile{
    position: relative;

    @include flex-column-center-center;
    gap: 1rem;

}

.each_chat_div_right{
    max-width: 88%;
    min-height: 3rem;


    @include flex-row-end-center;
    gap: 1rem;

    padding: 0.5rem;
    margin: 0rem 0.5rem;
    border: 1px solid $violet-secondary;
    border-radius: 1rem 1rem 0rem 1rem;
    background-color:$violet-primary-shade;
    
}



.chat_div_chat_icon{
    max-width: 3rem;
    min-width: 3rem;
    min-height: 3rem;
    max-height: 3rem;

    border: 3px solid $violet-light-shade;
    border-radius: 50%;

    overflow: hidden;

    & img{
        width: 100%;
        height: 100%;
    }
    
}
.active_status{
    position: absolute;
    top: 0.5rem;
    right: 0rem;

    width: 0.8rem;
    height: 0.8rem;
    background-color: $green-primary;
    border-radius: 50%;

    z-index: 1000;
}

.inactive_status{
    position: absolute;
    top: 0.5rem;
    right: 0rem;

    width: 0.8rem;
    height: 0.8rem;
    background-color: $gray-primary;
    border-radius: 50%;

    z-index: 1000;
}

.chat_div_chat_msg{
    @include mild-gray-text;
    @include flex-column-center-start;
    gap: 0.5rem;

    
    & div{
        @include mild-gray-text;

    }
}
.subscription_plan_div{
    @include mild-gray-text;
    margin-top: 1rem;

    & span{
        @include green-uppercase-text;

    }
}
.text_spinner{
    width: 1.8rem;
    margin-right: 1rem;
}

.chat_div_chat_msg_time_status_div{
    max-width: 2rem;
    min-height: 2rem;

    margin-right: 0.4rem;
    @include flex-column-end-center;
    gap: 0.3rem;

}

.chat_div_chat_msg_time_status_div_mobile{
    max-width: 2rem;
    min-height: 2rem;

    @include flex-column-end-center;
}


.chat_div_chat_msg_time{
    font-size: 0.8rem;
    color: #ffffff80;
    

}
.chat_div_chat_msg_status{
    color: $green-primary;
    @include flex-row-center-center;
}

.chat_div_chat_msg_status_gray{
    color: rgba(255, 255, 255, 0.5);
    @include flex-row-center-center;
}


.input_div{
    width: 98%;
    height: 4rem;
    min-height: 4rem;
    max-height: 4rem;

    @include flex-row-center-center;
    gap: 1rem;

    & input{
        width: 80%;
        height: 100%;
        background-color:transparent;

        border: 1px solid $violet-secondary;
        border-radius: 10rem;

        font-size: 1rem;
        font-family: "Quicksand Bold";
        color: rgba(255, 255, 255, 0.80);

        padding: 0rem 1rem;

    }
    & input:focus{
        border: 1px solid $violet-light;
        outline: none;
    }
    & input::placeholder {
        color: rgba(255, 255, 255, 0.60);
        opacity: 0.75;
    }
    & button{
        width: 20%;
        height: 95%;
        border-radius: 10rem;

        border: none;
        background-color: $green-primary-shade;

        font-size: 1.4rem;
        font-family: "League Spartan Bold";

        cursor: pointer;
        text-shadow:0 0 10px #fff, 0 0 10px #fff;

        @include flex-row-center-center;
  
    }
}


.input_div_inactive{
    width: 90%;
    height: 4rem;
    min-height: 4rem;
    max-height: 4rem;

    font-size: 1rem;
    color: $seperator_background;

    @include flex-row-center-center;
    gap: 1rem;
}
.anchor_tag{
    font-size: 1rem;
    color: $green-primary;
}
.anchor_tag_secondary{
    padding-left: 0.2rem;
    font-size: 1rem;
    color: $green-primary-shade;
}



.floating_alert{
    position: fixed;
    padding: 0.5rem 1rem;

    bottom: 4rem;
    left: 50%;
    transform: translate((-50%, -50%));

    border: 1px solid $red-primary;
    border-radius: 0.5rem;
    background-color: rgba(255, 0, 0, 0.777);

    max-width: 40%;
    @include default-text-light;
    z-index: 1000;

    // animation: appear_animation 4s ease 1s forwards;
}


// // tablet and mobile css section
// @media only screen and (max-width: 991px) {
//     .chat_div{
//         width: 100%;
//         height: 100%;
//         min-height: 100%;
//         max-height: 100%;
    
//         @include flex-column-end-center;
//         background-color: $violet-primary;
    
//     }
//     .chat_div_main_right{
//         width: 100%;
//         height: 100%;
//         @include flex-row-center-center;
        
//         box-shadow: inset 20rem 0 60rem 80px $violet-primary;
//         background: url('../../assets/images/chat.jpg') no-repeat;
//         background-position: center;
//         background-size: cover;
//         filter: brightness(100%);
//         box-shadow: 100px;
//     }
// }


























// only mobile css section
@media only screen and (max-width: 991px){
    .chat_div{
        width: 100%;
        height: 100%;
    
        @include flex-column-start-center;
        overflow-x: hidden;
        overflow-y: scroll;

        padding-top: 4.7rem;
        padding-bottom: 4rem;

        // border: 5px solid purple;
    }

    .chat_div_main_right{
        width: 100%;
        height: 100%;
        @include flex-row-center-center;
        
        box-shadow: inset 10rem 0rem 100rem 5rem $violet-primary;
        background: url('../../assets/images/chat.jpg') no-repeat;
        background-position: center;
        background-size: cover;
        filter: brightness(100%);
        box-shadow: 100px;
    }

    .chat_div_chat{
        width: 100%;
        height: 100%;
        @include flex-column-start-center;
        overflow-y: hidden;

        // background-color: fuchsia;
        // border: 5px solid fuchsia;
    }
    
    .chat_div_chat_scroll{
        width: 100%;
        height: 100%;
        min-height: 100%;
        padding: 0rem;

        @include flex-column-end-center;
        position: relative;

        // min-height: calc(100% - 9rem);
        // max-height: calc(100% - 9rem);
        // height: 100%;
        // max-height: 100%;
        // padding-top: 4.7rem;
        // border: 3px solid green;
        // background-color: green;
    }

    .chat_div_chat_scroll_100{
        width: 100%;
        height: auto;
        max-height: 100%;
        overflow-y: scroll;

        position: absolute;
        bottom: 0;
        padding: 0rem;

        // border: 3px solid red;
    }

    // .chat_div_chat_scroll_100_extra_padding{
    //     width: 100%;
    //     height: auto;
    //     max-height: 100%;
    //     overflow-y: scroll;

    //     position: absolute;
    //     top: 0;

    //     padding: 0rem;
    //     // padding-top: 0rem;
    //     // padding-left: 0rem;
    //     // padding-right: 0rem;
    //     // padding-bottom: 8rem;

    //     border: 3px solid violet;
    // }


    .each_chat_div_left{
        max-width: 92%;
        min-height: 3rem;
    }
    .each_chat_div_right{
        max-width: 92%;
        min-height: 3rem;        
    }
    // initial chat loading spinner
    .each_chat_div_parent_center{
        width: 100%;
        height: 70vh;
        @include flex-row-center-center;  
        overflow-y: hidden !important;

        // border: 1px solid red;
    }
    // bottom input section
    .input_div{
        width: 100%;
        height: 5rem;
        min-height: 5rem;
        max-height: 5rem;

        // border: 1px solid violet;
        background-color: $violet-primary;

        position: fixed;
        bottom: 0%;
        z-index: 1000;

        @include flex-row-center-center;
        gap: 0.5rem;
        margin-top: 0.5rem;
        padding: 0.5rem;

        & input{
            width: 80%;
            height: 100%;
            background-color:transparent;

            border: 1px solid $violet-secondary;
            border-radius: 10rem;

            font-size: 1rem;
            font-family: "Quicksand Bold";
            color: rgba(255, 255, 255, 0.80);

            padding: 0rem 1rem;

        }
        & input:focus{
            border: 1px solid $violet-light;
            outline: none;
        }
        & input::placeholder {
            color: rgba(255, 255, 255, 0.60);
            opacity: 0.75;
        }
        & button{
            width: 20%;
            min-width: 6rem;
            height: 95%;
            border-radius: 5rem;

            border: none;
            background-color: $green-primary-shade;

            font-size: 1.2rem;
            font-family: "League Spartan Bold";

            cursor: pointer;
            text-shadow:0 0 10px #fff, 0 0 10px #fff;

            @include flex-row-center-center;
        }
    }
    .input_div_inactive{
        width: 100%;
        height: 5rem;
        min-height: 5rem;
        max-height: 5rem;

        position: fixed;
        bottom: 0;
        z-index: 1000;

        // border: 1px solid purple;
        margin-top: 0.5rem;
        padding: 0.5rem;

        @include flex-row-center-center;
        gap: 1rem;

        & p {
            color: $seperator_background;
        }
    }
    .anchor_tag{
        font-size: 1rem;
        color: $green-primary;
    }
    .anchor_tag_secondary{
        padding-left: 0.2rem;
        font-size: 1rem;
        color: $green-primary-shade;
    }


    .floating_alert{
        bottom: 50%;
        max-width: 90%;
    }
}



