.app {
  width: 100%;
  height: 100%s;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.4);
}

/* 
@media screen and (max-width: 991px) {
  .app {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
  }

} */