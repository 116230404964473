@import './variables.module.scss';


@mixin default-text-light{
    color: white;
    font-size: 1rem;
    font-family: 'Quicksand Bold' !important;
}
@mixin default-text-dimmed{
    color: rgba(255, 255, 255, 0.80);
    font-size: 1rem;
    font-family: 'Quicksand Bold' !important;
}

@mixin gradient-text {
    background: linear-gradient(90deg, $violet-light, $orange-secondary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@mixin mild-gray-text{
    font-size: 1rem;
    font-family: 'Quicksand Bold';  
    color: rgba(255, 255, 255, 0.80);
}

@mixin green-uppercase-text{
    font-size: 1rem;
    font-family: 'Quicksand Bold';  
    color: $green-primary;
}

@mixin login-background{
    width: 100%;
    height: 100%;
    box-shadow: inset 5rem 0rem 50rem 5rem $violet-primary;
    background: url('../../assets/images/login.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 100px;
}
@mixin img-background{
    width: 100%;
    height: 100%;
    box-shadow: inset 5rem 0rem 50rem 5rem $violet-primary;
    background: url('../../assets/images/chat.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 100px;
}
@mixin custom-popup-background{
    width: 100%;
    height: 100%;
    box-shadow: inset 5rem 0rem 50rem 5rem $violet-primary;
    background: url('../../assets/images/custom_popup.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 100px;
}
@mixin admin-background{
    width: 100%;
    height: 100%;
    box-shadow: inset 5rem 0rem 50rem 5rem $violet-primary;
    background: url('../../assets/images/nature.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 100px;
}




@mixin gray-bottom-border {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 0.5rem;
    border-bottom-color: $horizontal-line-color;
}

@mixin page-container-default {
    background-color: $background-color-all-pages;
    padding-top: $padding-top-all-pages;
    padding-right: 3rem;
    padding-left: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow-y: scroll;
}

@mixin page-title {
    color: $violet-primary;
    font-weight: 600;
    font-size: 1.8rem;
    width: auto;
}

@mixin page-title-secondary{
    color: $violet-primary;
    font-weight: 600;
    font-size: 1.1rem;
    width: auto;
}

@mixin table-header {
    color: $violet-primary;
    font-weight: 500;
    text-align: center;
    padding-bottom: 1rem;
    min-width: 120px;
    font-size: 1.1rem;
}

@mixin table-default {
    margin-top: 1rem;
    width: 100%;
    font-size: 1.4rem;
    text-transform: capitalize;
    text-align: center;
   
    & thead th {
        scroll-padding: 0.5rem 0rem 0rem 0rem;

        position: sticky;
        top: 0;
        background-color: $input_background;
    }

    & th {
        @include table-header;
    }

}


// flex mixins for row
@mixin flex-row-center-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
@mixin flex-row-center-start{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}
@mixin flex-row-center-end{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
@mixin flex-row-start-center{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
@mixin flex-row-start-start{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
@mixin flex-row-start-end{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}
@mixin flex-row-end-center{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
@mixin flex-row-end-start{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}
@mixin flex-row-end-end{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}
@mixin flex-row-between-start{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
@mixin flex-row-around-start{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}
@mixin flex-row-evenly-start{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}
@mixin flex-row-between-center{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
@mixin flex-row-around-center{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
@mixin flex-row-evenly-center{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
@mixin flex-row-between-end{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
@mixin flex-row-around-end{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}
@mixin flex-row-evenly-end{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}



// three parameter
@mixin flex-row-between{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@mixin flex-row-evenly{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
@mixin flex-row-around{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}



// flex mixins for column
@mixin flex-column-center-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@mixin flex-column-center-start{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
@mixin flex-column-center-end{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
@mixin flex-column-start-center{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
@mixin flex-column-start-start{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
@mixin flex-column-start-end{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
@mixin flex-column-end-center{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
@mixin flex-column-end-start{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
@mixin flex-column-end-end{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
@mixin flex-column-between-start{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
@mixin flex-column-around-start{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}
@mixin flex-column-evenly-start{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}
@mixin flex-column-between-center{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
@mixin flex-column-around-center{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
@mixin flex-column-evenly-center{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
@mixin flex-column-between-end{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
@mixin flex-column-around-end{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
}
@mixin flex-column-evenly-end{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
}
// three parameter
@mixin flex-column-between{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@mixin flex-column-evenly{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
@mixin flex-column-around{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}












// ################################### mobile and tablet ####################################
// tablet css section
@media only screen and (max-width: 991px){
    @mixin login-background{
        width: 100%;
        height: 100%;
        box-shadow: inset 5rem 0rem 50rem 5rem $violet-primary;
        background: url('../../assets/images/login.jpg') no-repeat;
        background-position: center;
        background-size: 100%;
        box-shadow: 100px;
    }
    @mixin img-background{
        width: 100%;
        height: 100%;
        box-shadow: inset 5rem 0rem 50rem 5rem $violet-primary;
        background: url('../../assets/images/chat.jpg') no-repeat;
        background-position: center;
        background-size: 100%;
        box-shadow: 100px;
    }
    @mixin custom-popup-background{
        width: 100%;
        height: 100%;
        box-shadow: inset 5rem 0rem 50rem 5rem $violet-primary;
        background: url('../../assets/images/custom_popup.jpg') no-repeat;
        background-position: center;
        background-size: 100%;
        box-shadow: 100px;
    }
    @mixin admin-background{
        width: 100%;
        height: 100%;
        box-shadow: inset 5rem 0rem 50rem 5rem $violet-primary;
        background: url('../../assets/images/nature.jpg') no-repeat;
        background-position: center;
        background-size: 100%;
        box-shadow: 100px;
    }
}

// // only mobile css section
// @media only screen and (max-width: 767px){
//     @mixin login-background{
//         background-size: cover;
//     }
//     @mixin img-background{
//         background-size: cover;
//     }
//     @mixin custom-popup-background{
//         background-size: cover;
//     }
//     @mixin admin-background{
//         background-size: cover;
//     }
// }