/* league spartan font-face */
@font-face {
  font-family: "League Spartan";
  src: url("./assets/fonts/LeagueSpartan-Regular.ttf");
}
@font-face {
  font-family: "League Spartan Bold";
  src: url("./assets/fonts/LeagueSpartan-Bold.ttf");
}
@font-face {
  font-family: "League Spartan Thin";
  src: url("./assets/fonts/LeagueSpartan-Light.ttf");
}
/* space mono font-face */
@font-face {
  font-family: "Space Mono";
  src: url("./assets/fonts/SpaceMono-Regular.ttf");
}
@font-face {
  font-family: "Space Mono Bold";
  src: url("./assets/fonts/SpaceMono-Bold.ttf");
}
/* Quicksand font-face */
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand-Medium.ttf");
}
@font-face {
  font-family: "Quicksand Bold";
  src: url("./assets/fonts/Quicksand-Bold.ttf");
}
@font-face {
  font-family: "Quicksand Thin";
  src: url("./assets/fonts/Quicksand-Light.ttf");
}



*{
  margin: 0;
  padding: 0;

  font-family: 'League Spartan Bold';
  font-size: 0.9rem;
  color: #ffffff;  

  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}