@import '../../../assets/styles/mixins.module.scss';
@import '../../../assets/styles/variables.module.scss';


.container{
    width: 100%;
    height: 92%;

    min-height: 92%;
    max-height: 92%;

    @include flex-column-center-center;
    background-color: $violet-primary;
    gap: 0.2rem;

    text-align: center;


    & h1{
        font-size: 1.8rem;
        @include gradient-text;
    }
    & p{
        @include mild-gray-text;
    }

    & span {
        font-size: 1rem;
        font-family: 'Quicksand Bold'; 
        @include gradient-text;
    }
}
