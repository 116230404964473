@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';


.edit_div{
    width: 100%;
    height: 100%;
    overflow: scroll;

    @include flex-column-start-center;
    // @include img-background;
    // background-color: $violet-light;
    background-color: wheat;

    & h1{
        font-size: 1.2rem;
        font-family: 'Quicksand Bold';
        color: rgb(41, 41, 41);
        font-weight: bold;
    }
    & p {
        font-size: 1rem;
        font-family: 'Space Mono';

        color: rgb(41, 41, 41);
        font-weight: bold;
    }
    & textarea{
        color: rgb(41, 41, 41);
        font-weight: bold;
    }
}


.edit_div_form{
    @include flex-column-start-start;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    padding: 0rem 3rem;
    margin-bottom: 0.2rem;
    position: relative;
}
.go_to_top_btn{
    position: fixed;
    right: 3rem;
    bottom: 2rem;

    background-color: $green-primary-shade;
    box-shadow: 0px 0px 3rem $violet-light;
    padding: 0.6rem 0.8rem;
    border: 1px solid $green-primary-shade;
    border-radius: 0.5rem;

    font-weight: 1000;
    cursor: pointer;
}

.edit_div_heading{
    @include flex-row-between-start;

    margin-top: 1rem;
    width: 100%;

}

.edit_div_heading_left{
    @include flex-column-start-start;
}
.edit_div_heading_left_top{
    @include flex-row-start-center;
    gap: 0.5rem;
}

.edit_div_heading_right{
    @include flex-row-end-center;
    width: 20%;
    height: 100%;
    gap: 0.5rem;

    & button{
        height: 70%;
        padding: 0.5rem;

        background-color: $green-primary-shade;
        border: 1px solid $green-primary-shade;
        border-radius: 0.5rem;

        cursor: pointer;

    }

    & button.btn_red{
        background-color: red;
        border: 1px solid red;
    }

    & button.btn_disabled{
        background-color: gray;
        border: 1px solid gray;

    }

}

.each_input_div{
    @include flex-row-center-start;

    width: 100%;
    max-width: 100%;
    margin-top: 0.3rem;
    
}

.each_input_div_each{
    @include flex-column-start-center;

    width: 100%;
    max-width: 100%;

    height: 30rem;
    gap: 0.5rem;

    padding: 0.5rem 0rem;
    & p{
        font-size: 1rem;
        font-family: 'Quicksand Bold';

    }

    & small{
        font-size: 0.8rem;
        font-family: 'Space Mono';

        color: rgb(41, 41, 41);
        font-weight: bold;
    }

}

.label_div{
    @include flex-column-start-start;
    width: 100%;
}

.input_div{
    width: 100%;
    min-width: 100%;

    height: 100%;

    // min-height: 85%;
    // max-height: 85%;

    & textarea{
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
        
        // background-color: $violet-light-shade;
        background-color: wheat;
        border: 2px solid $violet-primary;
        font-family: 'Space Mono';

        padding: 0.5rem;


        // border: 1px solid $violet-light;
        border-radius: 0.5rem;

    }

}

.green_text{
    color: $green-primary;
    font-size: 0.8rem !important;
}
.red_text{
    color: red;
    font-size: 0.8rem !important;
}




// ################################### mobile and tablet ####################################
// tablet css section
@media only screen and (max-width: 991px){

    .edit_div_form{
        margin-top: 3rem;
        padding: 0.8rem;
    }


    .edit_div_heading_left{
        width: 70%;
    }

    .edit_div_heading_right{
        @include flex-column-center-end;
        gap: 0;
        width: 30%;

        // border: 1px solid red;

        & button{
            height: auto;
            padding: 0.5rem;
    
            background-color: $green-primary-shade;
            border: 1px solid $green-primary-shade;
            border-radius: 0.5rem;
    
            cursor: pointer;
    
        }
    }

    .go_to_top_btn{
        right: 2rem;
        bottom: 1.5rem;
    }
    
}

// only mobile css section
@media only screen and (max-width: 767px){
    
}

// only mobile css section
@media only screen and (max-width: 575px){
    
}