@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';

.container{
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    @include flex-column-center-center;
    @include login-background;

}

.login_container{
    min-width: 37rem;
    min-height: 25rem;
    
    max-width: 40%;
    max-height: 70%;

    @include flex-column-center-center;

    padding: 2rem 2.5rem;
    background-color: #1f002e;
    border-radius: 1.5rem;

    box-shadow: 0px 0px 1000px 0px $violet-light;

}

.login_container_form{
    width: 100%;
    height: 100%;

    @include flex-column-between-center;

}

.login_container_form_top{
    width: 100%;
    height: auto;
    @include flex-column-center-center;

}
.login_remark{
    font-size: 1rem;
    font-family: 'Quicksand';
}

.reset_remark{
    font-size: 1rem;
    font-family: 'Quicksand';
    
    & a{
        font-size: 1rem;
        font-family: 'Quicksand';
        padding-left: 0.5rem;
        @include gradient-text;
        text-decoration: underline !important;
    }
}



.title_div{
    font-size: 1.8rem;
    background: linear-gradient(90deg, $violet-light, $orange-secondary);
    background-clip: text;
    -webkit-text-fill-color: transparent;

    margin-top: 1rem;  
    margin-bottom: 1rem;  
}

.input_div {
    width: 100%;
    height: auto;

    @include flex-column-center-start;
    gap: 0.8rem;
    margin-bottom: 1rem;

}



.login_container_form_bottom{
    width: 100%;
    height: auto;
    @include flex-row-between-center;

    gap: 1rem;
}
.alert_div{
    width: auto;
    height: auto;

    @include flex-row-start-center;

    font-family: 'Quicksand';
    font-size: 1rem;
    color: $red-primary;
}
.success_text{
    color: $green-primary;
    font-family: 'Quicksand';
    font-size: 1rem;
}
.btn_div{
    width: auto;
    height: auto;

    @include flex-row-end-center;

}







// ################################### mobile and tablet ####################################
// tablet css section
@media only screen and (max-width: 991px){
    .login_container{
        min-width: 35rem;
        max-width: 70%;    
        padding: 2rem 1.5rem;
    }
}

// only mobile css section
@media only screen and (max-width: 767px){
    .login_container{
        min-width: 90%;
        max-width: 90%;
        padding: 1.5rem 1rem;
    }
}


// only mobile css section
@media only screen and (max-width: 575px){
    .login_container{
        min-width: 97%;
        max-width: 97%;
        padding: 1.5rem 1rem;
    }
}