@import '../../assets/styles/mixins.module.scss';
@import '../../assets/styles/variables.module.scss';


// animation
@keyframes cross_btn_animation {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(90deg)    
    }
  }



.container{
    width: 100%;
    min-width: 100%;
    max-width: 100%;

    height: 100%;
    min-height: 100%;
    max-height: 100%;

    background-color: $violet-secondary;
    @include flex-column-start-center;
    overflow: hidden;

    position: fixed;
    bottom: 0;
    z-index: 1001;
    padding: 5rem 0rem;

    & div{
        font-family: 'League Spartan Bold';
    }
}


.slider_main_div{
    min-width: 60%;
    max-width: 60%;
    min-height: 40%;

    @include flex-column-between-start;
    gap: 1rem;

    padding: 1.5rem 0.5rem;
    border-radius: 0.5rem;
    background-color: $violet-bg-shade;

    & div{
        width: 100%;
    }
}
.slider_main_div_top{
    @include flex-column-between-start;
    gap: 1rem;

}

.slider_top_div{
    width: 100%;
    height: auto;

    @include flex-row-between-start;
    padding: 0rem 1rem;
}

.slider_top_div_left{
    width: 90%;
    height: 100%;

    font-weight: 1000 !important;
    @include flex-column-start-center;
    gap: 0.2rem;
}
.slider_title{
    font-size: 1.4rem;
    text-transform: capitalize;
}
.slider_subtitle{
    font-size: 1rem;
    font-family: 'Quicksand bold' !important;
    color: rgba(255, 255, 255, 0.80);

}

.slider_top_div_right{
    max-width: 10%;
    height: 100%;

    @include flex-column-start-end;
}
.slider_cross_btn{
    width: auto !important;
    height: auto !important;
    cursor: pointer;
}
.slider_cross_icon{
    width: 100%;
    animation: cross_btn_animation 0.5s linear;
}

.slider_middle_div{
    width: 100% !important;
    height: auto;
    @include flex-row-between-start;

    background-color: $violet-bg-shade;
    border-radius: 1rem;
    padding: 1rem;

}
.slider_middle_div_bottom_left{
    min-width: 75% !important;

    & div{
        font-size: 1rem;
    }
}
.slider_middle_div_top{
    font-size: 1.4rem !important;

    @include flex-row-start-center;
    gap: 1rem;
}
.slider_middle_div_bottom{
    font-family: 'Quicksand Bold' !important;
    color: rgba(255, 255, 255, 0.80);

    @include flex-column-start-start;
    margin-top: 0.2rem;
}
.subscirption_data_table{
    min-width: 50% !important;
    max-width: 100% !important;
    margin-top: 1rem;

}
.subscirption_data_row{
    @include flex-row-between-center;

    & div{
        font-family: 'Quicksand Bold' !important;
        color: rgba(255, 255, 255, 0.80);
        padding: 0.5rem 0rem;
    }
}

.slider_middle_div_bottom_right{
    min-width: auto !important;
    @include flex-row-end-center;

}
.subscription_btn{
    width: auto;
    height: auto;
    padding: 0.8rem 1rem;
    border-radius: 10rem;
    border: 0;
    background-color: $green-primary-shade;

    font-size: 1rem;
    font-family: 'Quicksand Bold' !important;

    cursor: pointer;
    text-shadow:0 0 2px #fff, 0 0 10px #fff;

    @include flex-row-center-center;
}





// bottom div
.slider_bottom_div{
    width: 100% !important;
    height: auto;
    @include flex-row-between-start;
    padding: 0rem 1rem;
}
.slider_bottom_div_left{
    min-width: 90%;

    @include flex-column-start-center;
    gap: 0.2rem;
    padding-bottom: 1rem;
}
.slider_bottom_div_top{
    font-size: 1.4rem;
}
.slider_bottom_div_bottom{
    @include default-text-dimmed;
    @include flex-row-between-start;
}

.slider_bottom_div_right{
    min-width: 10%;
    height: 100%;
    @include flex-column-center-end;
}
.logout_btn{
    width: auto;
    height: auto;
    padding: 0.8rem;
    border-radius: 10rem;
    border: 1px solid $red-primary;
    background-color: $red-primary;

    font-size: 1rem;
    font-family: 'Quicksand Bold' !important;

    cursor: pointer;
    text-shadow:0 0 2px #fff, 0 0 10px #fff;

    @include flex-row-center-center;
}

.failed_alert_div{
    @include default-text-dimmed;
    padding: 0rem 1rem;

}

.loading_div{
    width: 1rem !important;
    height: 1rem !important;

    & div{
        width: 100%;
        height: 100%;
    }
}






// tablet and mobile css section
@media only screen and (max-width: 991px) {
    .container{
        @include flex-column-start-center;
        padding: 3rem 0.5rem;
    }
    .slider_main_div{
        min-width: 80%;
        max-width: 80%;    
        // padding: 2rem 1.5rem;

        gap: 2rem;

        padding: 1.5rem 0rem;
        border-radius: 0.5rem;
        background-color: $violet-bg-shade;

        @include flex-column-start-start;
    }

    .slider_top_div{
        height: auto;
    }

    .slider_middle_div{
        height: auto;
        @include flex-column-start-center;
    }
    .subscirption_data_table{
        width: 100% !important;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .slider_middle_div_bottom_right{
        margin-top: 0.5rem;
        @include flex-row-start-center;
    }


    .slider_bottom_div{
        @include flex-column-between-start;
    }
    .slider_bottom_div_right{
        @include flex-row-start-center;
    }
}

// only mobile css section
@media only screen and (max-width: 767px){
    .slider_main_div{
        min-width: 90%;
        max-width: 90%;

    }
}

// only mobile css section
@media only screen and (max-width: 575px){
    .slider_main_div{
        min-width: 100%;
        max-width: 100%;

    }
}