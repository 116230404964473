@import '../../assets/styles/mixins.module.scss';
@import '../../assets/styles/variables.module.scss';


.alert_container{
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    @include flex-column-center-center;
    @include login-background;
}

.alert_div{
    min-width: 37rem;
    min-height: 25rem;
    
    max-width: 40%;
    max-height: 70%;

    @include flex-column-between-center;

    padding: 3rem 2.5rem;
    background-color: #1f002e;
    border-radius: 0.5rem;

    box-shadow: 0px 0px 1000px 0px $violet-light;
}

.alert_loading_div{
    width: 100%;
    height: 100%;

    @include flex-column-center-center;
}

.alert_title{
    font-size: 1.8rem;
    @include gradient-text;
}


.alert_des_div{
    width: 100%;
    height: 70%;

    margin-top: 1.5rem;

    @include flex-column-start-start;
    gap: 1rem;

    // border: 1px solid red;

}

.alert_des{
    font-size: 1rem;
    font-family: 'Quicksand';
}

.alert_des_login{
    font-size: 1rem;
    font-family: 'Quicksand';

    @include flex-row-start-center;
    gap: 0.5rem;

    & a{
        font-size: 1rem;
        @include gradient-text;
        text-decoration: underline !important;
    }

}


.alert_option{
    width: 100%;
    @include flex-row-end-center;

    gap: 0.8rem;

}





// ################################### mobile and tablet ####################################
// tablet css section
@media only screen and (max-width: 991px){
    .alert_div{
        min-width: 35rem;
        max-width: 70%;    
        padding: 2rem 1.5rem;
    }
}

// only mobile css section
@media only screen and (max-width: 767px){
    .alert_div{
        min-width: 90%;
        max-width: 90%;
        padding: 1.5rem 1rem;
    }
}

// only mobile css section
@media only screen and (max-width: 575px){
    .alert_div{
        min-width: 97%;
        max-width: 97%;
        padding: 1.5rem 1rem;
    }
}