@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';


.container {
    width: 100%;
    min-width: 100%;
    max-width: 100%;

    height: 8%;
    min-height: 8%;
    max-height: 8%;
    
    @include flex-row-between-center;
    background-color: $color-prompt;

    z-index: 1000;

    padding: 0rem 2rem;
}

.navbar_main{
    width: 100%;
    height: 100%;
    @include flex-row-between-center;
    gap: 0.5rem;

    // padding: 0rem 2rem;
    text-decoration: none;

}

.navbar_main_left{
    margin: 1rem;
    @include flex-row-center-center;
    gap: 0.5rem;
    cursor: pointer;
    
    & div{
        font-size: 1.4rem;
    }
}
.navbar_main_right{
    margin: 1rem;
    @include flex-row-end-center;
    cursor: pointer;
}



// tablet and mobile css section
@media only screen and (max-width: 991px) {
    .container {
        width: 100%;

        height: 4rem;
        min-height: 4rem;
        max-height: 4rem;

        position: fixed;
        top: 0;
        
        @include flex-row-between-center;
        background-color: $color-prompt;    

        z-index: 1000;
    }

    .navbar_main{
        width: 100%;
        height: 100%;
        @include flex-row-between-center;
    
        padding: 0rem;
        text-decoration: none;
    
    }

    .navbar_main_left{
        @include flex-row-center-center;
        gap: 0.5rem;
        cursor: pointer;
        
        & div{
            font-size: 1.4rem;
        }
    }
    .navbar_main_right{
        @include flex-row-end-center;
        cursor: pointer;

    }
}