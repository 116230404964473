@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';


.prompt_container{
    width: 100vw;
    height: 100vh;

    @include flex-column-start-center;
    
}