@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';


.container{
    width: 100%;

    @include flex-column-center-start;
    gap: 0.25rem;
    
}

.label_div{
    font-size: 1.1rem;
    color: $violet-light;
    font-family: 'space mono bold';


}


.input_div{
    width: 100%;
    height: 20%;
    min-height: 3.5rem;

    background-color: transparent;
    color: $violet-light;
    padding:  0rem 0.5rem;

    border: 2px solid $violet-light;
    border-radius: 0.5rem;

    font-size: 1rem;
    font-family: 'Space Mono';

    background: linear-gradient(90deg, $violet-light, 30%, $orange-secondary);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.input_div:focus{
    outline: none;
}

.input_div::placeholder {
    color: $violet-light;
    opacity: 1; /* Firefox */

  }
