@import '../../assets/styles/variables.module.scss';

.container {
    height: 100%;
    min-width: auto;
    max-width: 12rem;

    text-align: center;
    font-size: 1.2rem;
    padding: 0.8rem 1rem;

    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.3s;
}

.default {
    &:hover {
        color: white;
    }
}


.blue {
    border-color: $violet-primary;
    color: $violet-primary;

    &:hover {
        background-color: $violet-primary;
    }
}

.green {
    border-color: $green-primary-shade;
    color: $green-primary-shade;

    &:hover {
        background-color: $green-primary;
    }
}

.red {
    border-color: $red-primary;
    color: $red-primary;

    &:hover {
        background-color: $red-primary;
    }
}

.orange {
    border-color: orange;
    color: orange;

    &:hover {
        background-color: orange;
    }
}

.purple {
    border-color: purple;
    color: purple;

    &:hover {
        background-color: purple;
    }
}

.reverse {
    color: white !important;

    &_blue {
        background-color: $violet-primary;
        box-shadow: 0px 8px 12px rgba(25, 39, 101, 0.3);
    }

    &_red {
        background-color: $red-primary;
        box-shadow: 0px 0px 15px $red-primary;
    }

    &_green {
        background-color: $green-primary-shade;
        box-shadow: 0px 0px 15px $green-primary;
    }

    &_orange {
        background-color: orange;
        box-shadow: 0px 8px 12px rgba(252, 174, 5, 0.705)
    }

    &_purple{
        background-color: purple;
        box-shadow: 0px 8px 12px rgb(94, 0, 94);
    }
}

.disabled {
    color: white !important;
    background-color: gray;
    border-color: gray !important;
    cursor: not-allowed !important;

}

@media screen and (max-width: 500px) {
    .container {
        width: unset;
    }
    
}


